<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true">
      <el-form-item label="课程名">
        <el-input v-model="query.name" placeholder="课程名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #is_settlement="{ row }">
        <div>
          <el-tag type="success" size="small" v-if="row.is_settlement"
            >已结算</el-tag
          >
          <el-tag type="info" size="small" v-else>未结算</el-tag>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <p  v-if="row.status == 1"
            >待上课</p
          >
         <p v-if="row.status == 2"
            >已上课</p
          >
        </div>
      </template>
      <template #price="{ row }">
        <div v-if="row.map.need">
         <p v-if="row.map.need.mechanism_id">
            {{row.map.need.settlement_type == 1 ? row.map.need.mechanism_settlement_cash : row.map.need.settlement_cash * row.map.need.mechanism_profit}}
         </p>
           <p v-else-if="row.map.need.coach_id">
           {{row.map.need.settlement_type == 1 ? row.map.need.coach_settlement_cash : row.map.need.settlement_cash * row.map.need.coach_profit}}
         </p>
        </div>
      </template>
      <template #mecname="{ row }">
        <div v-if="row.map.masterMechanismEntity">
          {{ row.map.masterMechanismEntity.mechanism_name }}
        </div>
      </template>
      <template #handle="{ row }">
        <el-button size="small" v-if="!row.is_settlement" type="primary" @click="settlement(row)"
          >结算课程</el-button
        >
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "社区课程管理", secondNav: "社区课程管理" },
      DataList: [],
      query: {
        name: "",
      },
      Option: [
        { name: "课程名称", value: "title" },
        { name: "开始时间", value: "start_time" },
        { name: "结束时间", value: "end_time" },
         { name: "结算金额", value: "price", type: "custom" },
        { name: "是否结算", value: "is_settlement", type: "custom" },
         { name: "是否上课", value: "status", type: "custom" },
        { name: "上课机构", value: "mecname", type: "custom" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    settlement(row) {
      if(row.status === 1){
        this.$message("课程未核销")
        return
      }
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          text:'正在核销...'
        });
      this.$axios({
        url: "/user/mechanismClasses/communitySettlement",
        method: "post",
        data: {
          id:row.id,
          start_time: row.start_time,
          end_time: row.end_time
        },
      }).then((res) => {
        this.$message(res.data.message);
        this.getList(1)
        loading.close()
      }).catch(err=>{
        loading.close()
      })
    },
    //
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/masterAppointment/queryByUnionSettle",
        params: {
          currentPage: page,
          pageSize: 10,
          title: this.query.name || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>